import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";

import { useEditCompanyProducts, useUpdateCompany } from "@api/products";
import { useCompanyInformation } from "@api/companies";

import { getErrorMessage } from "@utils/errors";
import { usePreviousState } from "@utils/state";

import { useStyles } from "./styles";

import { useProductsContext } from "~/views/admin/dealer-management/[companyId]/products/_lib/context";
import { useFeedbackContext } from "~/components/Feedback";

import { withStyles } from "@material-ui/core/styles";

import { Input } from "~/components/Base/BaseInputs";
import { BaseForm } from "~/components/Base/BaseForm";
import { Button } from "~/components/Base/BaseActions";
import { Typography } from "~/components/Typography";
import { ProductLogo } from "~/components/Products";
import ProductCard from "../components/ProductCard";
import Toggle from "../components/Toggle";
import ProductLabel from "../components/ProductLabel";



import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Box from "@material-ui/core/Box";

import type { CompanyProduct, Product } from "@api/graphql/types";
import type { IEditProductAccessState, ToggleProps, IEditProductAccessFormProps } from "@admin/types";
import type { ILabelWithLogo, IProduct } from "../types";

type EditProductAccessStateHandlerKeys = keyof IEditProductAccessState;
type EditProductAccesspStateHandlers = Record<EditProductAccessStateHandlerKeys, (val: string) => void>;

const isEnabledProduct = (company: any, name: string): boolean => {
    if (!company || !name) {
        return false;
    }

    return !!company.companyProducts.nodes.map((cp?: CompanyProduct) => cp?.product)?.find((p?: Product) => p?.name === name)?.enabled;
};

export const ToolSwitch: React.FC<any> = ({ product, toggle, companyId }) => {
    const { company } = useCompanyInformation(companyId);
    const [enabled, setEnabled] = useState(isEnabledProduct(company, product?.name || ""));
    const styles = useStyles();

    const handleToggle = async () => {
        try {
            const toggled = toggle({ enabled, companyId: companyId, productId: product.id, name: product?.name });
            setEnabled(toggled);
        } catch (error) {
            console.error(error);
        }
    };

    const Label = withStyles((theme) => ({
        label: theme.typography.inputLabel,
    }))(FormControlLabel);

    const LabelWithLogo: React.FC<ILabelWithLogo> = ({ logo, title }) => {

        return (
            <div className={styles.labelWithLogo}>
                {logo && logo}
                <Typography variant="subtitle1">{`Enable ${title}`}</Typography>
            </div>
        );
    };

    return (
        <Label
            className={styles.toggle}
            key={product.name}
            control={(
                <Switch
                    checked={enabled}
                    onClick={handleToggle}
                />
            )}
            labelPlacement="start"
            label={<LabelWithLogo title={product.displayName} />}
        />
    );
};

export const EditProductAccessForm: React.FC<IEditProductAccessFormProps> = ({ companyId }) => {
    const { products, companyMeta, setAutoSms, setDisableTradeValue } = useProductsContext();
    const { handleOpenFeedback } = useFeedbackContext();
    const { company } = useCompanyInformation(Number(companyId));

    const { updateCompany } = useUpdateCompany();

    const [loading, setLoading] = useState(false);
    const [error, _setError] = useState("");
    const setError = (message: string) => handleOpenFeedback({ message, severity: "error" });
    const setSuccess = (message: string) => handleOpenFeedback({ message, severity: "success" });

    const styles = useStyles();

    const [inputValues, updateValue] = useState<IEditProductAccessState>({
        equifaxId: company?.equifaxCustomerNumber || "",
        securityCode: company?.securityCode || "",
        googlePlacesId: company?.googlePlacesId || "",
        lenderDealerCode: company?.lenderDealerCode || "",
    });

    const previousCompany = usePreviousState(company?.id);

    useEffect(() => {
        if (previousCompany !== company?.id) {
            updateValue({
                equifaxId: company?.equifaxCustomerNumber || "",
                securityCode: company?.securityCode || "",
                googlePlacesId: company?.googlePlacesId || "",
                lenderDealerCode: company?.lenderDealerCode || "",
            });
        }
    }, [company?.equifaxCustomerNumber, company?.id, company?.securityCode, company?.googlePlacesId, previousCompany, company?.lenderDealerCode]);

    const inputHandler = useMemo<EditProductAccesspStateHandlers>(() => {
        const scopedHandler = (key: EditProductAccessStateHandlerKeys) => (val: string) => {
            updateValue((state) => ({
                ...state,
                [key]: val,
            }));
        };
        return Object.keys(inputValues).reduce((acc, key) => ({
            ...acc,
            [key as EditProductAccessStateHandlerKeys]: scopedHandler(key as EditProductAccessStateHandlerKeys),
        }), {} as EditProductAccesspStateHandlers);
    }, [updateValue, inputValues]);

    const creditProduct = isEnabledProduct(company, "CREDIT_TOOL");
    const lenderProduct = isEnabledProduct(company, "LENDER_TOOL");
    const reviewsProduct = isEnabledProduct(company, "REVIEW_TOOL");

    const handleSubmit = async () => {
        if (!loading) {
            setLoading(true);
            try {
                if ((!inputValues?.equifaxId || !inputValues?.securityCode) && creditProduct) {
                    setError("Equifax id and security code are mandatory with Lender Connect and Credit");
                    return;
                }
                if ((!inputValues?.equifaxId || !inputValues?.securityCode || !inputValues?.lenderDealerCode) && lenderProduct) {
                    setError("Equifax id, security code and lender code are mandatory with Lender Connect");
                    return;
                }
                if (!inputValues?.googlePlacesId && reviewsProduct) {
                    setError("Google Places Id is mandatory with AVA Review");
                    return;
                }
                company && await updateCompany(company.id, inputValues?.equifaxId, inputValues?.securityCode, inputValues?.googlePlacesId, inputValues?.lenderDealerCode);
                setSuccess("Your changes has been saved!");
            } catch (error) {
                setError(getErrorMessage(error));
            } finally {
                setLoading(false);
            }
        }
    };

    const { deleteCompanyProduct, createCompanyProduct } = useEditCompanyProducts();

    const toggleTool = (props: ToggleProps) => {
        const { enabled, productId, name } = props;
        if (enabled) {
            deleteCompanyProduct(Number(companyId), productId);
            handleOpenFeedback({ message: `${name} has been disabled.`, severity: "warning" });
            return false;
        } else {
            if (productId === 1 || productId === 3) {
                if (!company?.equifaxCustomerNumber || !company?.securityCode) {
                    setError("Equifax id and security code are mandatory, please save them before continuing.");
                    return false;
                }
            }
            if (productId === 3) {
                if (!company?.equifaxCustomerNumber || !company?.securityCode || !company?.lenderDealerCode) {
                    setError("Equifax id, security code and lender code are mandatory, please save them before continuing.");
                    return false;
                }
            }
            if (productId === 7) {
                if (!company?.googlePlacesId) {
                    setError("Google Places Id is mandatory, please save them before continuing.");
                    return false;
                }
            }
            createCompanyProduct(Number(companyId), productId);
            setSuccess(`${name} has been enabled.`);
            return true;
        }
    };

    const refs = useRef<HTMLDivElement[]>([]);

    const getInputs = (productName: string) => {
        switch (productName) {
            case "CREDIT_TOOL":
                return (
                    <Fragment>
                        <Input
                            label="Equifax"
                            placeholder="Equifax ID"
                            id={"equifaxId"}
                            update={inputHandler.equifaxId}
                            value={inputValues.equifaxId}
                        />
                        <Input
                            placeholder="Equifax Security Code"
                            id={"input-edit-product-access-security-code"}
                            update={inputHandler.securityCode}
                            value={inputValues.securityCode}
                        />
                    </Fragment>
                );
            case "REVIEW_TOOL":
                return (
                    <Input
                        label="Google Places"
                        placeholder="Google Places ID"
                        id={"input-edit-product-access-google-id"}
                        update={inputHandler.googlePlacesId}
                        value={inputValues.googlePlacesId}
                    />
                );
            case "LENDER_TOOL":
                return (
                    <Input
                        label="Inovatec"
                        placeholder="Dealer Code"
                        id={"input-edit-product-access-lender-code"}
                        update={inputHandler.lenderDealerCode}
                        value={inputValues.lenderDealerCode}
                    />
                );
            case "TRADE_TOOL":
                const helper = !companyMeta?.tradeTool?.disableTradeValue ? `Your customers will see the exact KBB Trade-In Value.` : `Your customers will only see a range for KBB Trade-In Value.`;
                return (
                    <Toggle
                        label="KKB: Only show range"
                        helper={helper}
                        value={!!companyMeta?.tradeTool?.disableTradeValue}
                        setValue={setDisableTradeValue}
                    />
                );
            case "ID_TOOL":
                return (
                    <Toggle
                        label="AVA Credit: Auto-SMS"
                        helper="Automatically Request ID verification for all AVA Credit leads."
                        value={!!companyMeta?.idTool?.autoSendSms}
                        setValue={setAutoSms}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Box className={styles.root}>
            <Box className={styles.productNav}>
                {products?.map((p: IProduct, index: number) => {
                    return (
                        <ProductLabel
                            key={p.displayName}
                            onClick={() => refs?.current[index]?.scrollIntoView({ behavior: "smooth" })}
                            displayName={p.displayName}
                            productName={p.name}
                        />
                    );
                })}
            </Box>
            <Box className={styles.form}>
                <BaseForm error={error} className={styles.formContent}>
                    {products?.map((p: IProduct, index: number) => {
                        return (
                            <div style={{ width: "100%" }} ref={(el: HTMLDivElement) => (refs.current[index] = el)} key={p.name}>
                                <ProductCard
                                    logo={<ProductLogo productName={p.name} />}
                                    displayName={p.displayName}
                                    inputs={getInputs(p.name)}
                                    toggle={
                                        <ToolSwitch
                                            companyId={Number(companyId)}
                                            product={p}
                                            toggle={toggleTool}
                                        />}
                                />
                            </div>
                        );
                    })}
                    <Box className={styles.button}>
                        <Button
                            submit
                            label="SAVE"
                            onClick={handleSubmit}
                            loading={loading}
                        />
                    </Box>
                </BaseForm>
            </Box>
        </Box>
    );
};

