import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        width: "100%",
    },
    form: {
        width: "100%",
        [theme.breakpoints.up(theme.breakpoints.values.md)]: {
            width: "50%",
        },
    },
    formContent: {
        backgroundColor: "transparent",
        boxShadow: "none",
    },
    button: {
        transition: "none",
        width: "100%",
        "& .MuiGrid-item": {
            padding: "0 !important",
        },
    },
    labelWithLogo: {
        display: "flex",
        gap: 8,
    },
    productNav: {
        display: "none",
        [theme.breakpoints.up(theme.breakpoints.values.md)]: {
            display: "flex",
            flexDirection: "column",
            width: "33%",
            gap: 18,
            marginTop: 64,
            padding: 12,
            fontSize: 12,
        },
    },
    toggle: {
        display: "flex",
        justifyContent: "space-between",
        marginRight: 0,
    },
}));