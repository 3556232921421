import React from "react";

import { ProductLogo } from "~/components/Products";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { customStyles } from "./styles";

import { IProductLabel } from "./types";

const ProductLabel: React.FC<IProductLabel> = ({ productName, displayName, onClick }) => {
    const styles = customStyles();
    return (
        <Box onClick={onClick} className={styles.product}>
            <ProductLogo productName={productName} />
            <Typography variant="subtitle2">{displayName}</Typography>
        </Box>
    );
};

export default ProductLabel;