import React from "react";

import { customStyles } from "./styles";

import Switch from "@material-ui/core/Switch";

import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

import type { IToggleProps } from "./types";

const Toggle = ({ value, setValue, title, label, helper }: IToggleProps) => {
    const styles = customStyles();
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.checked);
    };
    return (
        <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">{title}</FormLabel>
            <FormGroup>
                <FormControlLabel
                    className={styles.toggle}
                    labelPlacement="start"
                    control={
                        <Switch checked={value} onChange={handleChange} />
                    }
                    label={label}
                />
            </FormGroup>
            <FormHelperText className={styles.toggleHelper}>{helper}</FormHelperText>
        </FormControl>
    );
};

export default Toggle;