import { makeStyles } from "@material-ui/core/styles";

export const customStyles = makeStyles(() => ({
    product: {
        display: "flex",
        alignItems: "center",
        gap: 12,
        cursor: "pointer",
        width: "max-content",
    },
}));