import React from "react";

import { createCompanyView, ViewComponent } from "../../_lib/view";
import { ProductsProvider } from "./_lib/context";

import { ProducstDisplay } from "./_lib/ProductsDisplay";

const ProductsView: ViewComponent = (props) => {
    return (
        <ProductsProvider>
            <ProducstDisplay {...props} />
        </ProductsProvider>
    );
};

export default createCompanyView(ProductsView, {
    title: "Products",
    internalPad: true,
});