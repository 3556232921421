import React, { useMemo } from "react";

import { useAuth } from "@api/auth";

import { ViewComponent } from "@settings/view";

import { Redirect } from "~/components/Redirect";

import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import { EditProductAccessForm } from "./forms/EditProductAccessForm";
import { GlobalLoader } from "@settings/GlobalLoader";
import { useProductsContext } from "./context";
import { useCompanyInformation } from "@api/companies";

const useStyles = makeStyles(() => ({
    content: {
        width: "100%",
        backgroundColor: "#F8FAFC",
        padding: 32,
    },
}));

export const ProducstDisplay: ViewComponent = (props) => {
    const { companyId } = props;
    const { loading } = useProductsContext();
    const { company } = useCompanyInformation(Number(companyId));
    const { user } = useAuth();
    const styles = useStyles();
    const content = useMemo(() => {
        if (!user?.autocorpAdmin) {
            return <Redirect to="/leads/" />;
        }
        if (loading && !company) {
            return <GlobalLoader />;
        } else {
            return <EditProductAccessForm companyId={props?.companyId} />;
        }

    }, [company, loading, props?.companyId, user?.autocorpAdmin]);

    return (
        <Box className={styles.content}>
            {content}
        </Box>
    );
};