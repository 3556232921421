import { makeStyles } from "@material-ui/core/styles";

export const customStyles = makeStyles(() => ({
    toggle: {
        display: "flex",
        justifyContent: "space-between",
        marginRight: 0,
    },
    toggleHelper: {
        marginLeft: 16,
    },
}));