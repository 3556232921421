import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { Typography } from "~/components/Typography";
import Paper from "@material-ui/core/Paper";
import Grid, { GridProps } from "@material-ui/core/Grid";
import clsx from "clsx";

const useStyles = makeStyles((theme) => {
    const spacing = theme.shape.authFormGutter;
    const indSpacing = theme.shape.authFormGutter / 2;

    return {
        root: {
            padding: 40,
        },
        title: {
            marginBottom: 15,
        },
        grid: {
            margin: indSpacing * -1,
            width: `calc(100% + ${spacing}px)`,
            "& .MuiGrid-item": {
                padding: indSpacing,
                display: "flex",
                flexFlow: "row nowrap",
            },
        },
    };
});


interface IBaseFormProps {
    title?: string;
    error?: string | null;
    className?: string;
}

export const BaseForm: React.FC<IBaseFormProps> = ({
    title = "",
    error = "",
    className,
    children,
}) => {
    const styles = useStyles();

    return (
        <Paper className={clsx(styles.root, className)}>
            <Typography variant="h1" className={styles.title}>{title}</Typography>
            <form onSubmit={(e) => e.preventDefault()}>
                <Grid
                    container
                    classes={{
                        root: styles.grid,
                    }}
                >
                    {children}
                </Grid>
            </form>
            {error && (
                <Box display="flex" justifyContent="center" pt={1}>
                    <Typography color="error">{error}</Typography>
                </Box>
            )}
        </Paper>
    );
};

interface IFormLabelProps extends GridProps {
    label: string;
    close?: boolean;
}

const labelStyles = makeStyles<
    Theme,
    Pick<IFormLabelProps, "close">
>(() => ({
    grid: ({ close = false }) => (
        close ? {
            paddingBottom: 0,
        } : {}
    ),
}));

export const FormLabel: React.FC<IFormLabelProps> = ({
    label,
    close,
    style = {},
    ...gridProps
}) => {
    const gridStyle: React.CSSProperties = close ? {
        paddingBottom: 0,
        ...style,
    } : style;

    return (
        <Grid
            item
            style={gridStyle}
            {...gridProps}
        >
            <Typography variant="subtitle1">
                {label}
            </Typography>
        </Grid>
    );
};