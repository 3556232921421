import React, { useMemo, forwardRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid, { GridProps } from "@material-ui/core/Grid";

import { Input as InputComponent } from "~/components/Input";
import { Password as PasswordComponent } from "~/components/Password";

const useStyles = makeStyles(({ palette }) => ({
    root: {
        backgroundColor: palette.background.default,
    },
}));

type InputProps = React.ComponentProps<typeof InputComponent> & IBaseProps;
type PasswordProps = React.ComponentProps<typeof PasswordComponent> & IBaseProps;

interface IBaseProps {
    grid?: GridProps;
}

interface IInputBaseProps extends IBaseProps, InputProps {
    component: "input";
}

interface IPasswordBaseProps extends IBaseProps, PasswordProps {
    component: "password";
}

type ComponentTypes = (IInputBaseProps | IPasswordBaseProps)["component"];

const componentIndex: Record<ComponentTypes, React.FC> = {
    input: InputComponent,
    password: PasswordComponent,
};

const InputBase = forwardRef<
    HTMLInputElement,
    IInputBaseProps | IPasswordBaseProps
>(function InputBase({
    component,
    InputProps = {},
    grid = {},
    ...props
}, ref) {
    const styles = useStyles();
    const Component = componentIndex[component];
    const gridProps = useMemo<GridProps>(() => ({
        xs: 12,
        ...grid,
    }), [grid]);
    const classes = useMemo(() => ({
        ...(InputProps.classes || {}),
        ...styles,
    }), [InputProps.classes, styles]);

    return (
        <Grid item {...gridProps}>
            <Component
                {...(props as IInputBaseProps & IPasswordBaseProps)}
                ref={ref}
                InputProps={{
                    ...InputProps,
                    color: "secondary",
                    classes,
                }}
            />
        </Grid>
    );
});

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(props, ref) {
    return (
        <InputBase component="input" {...props} ref={ref} />
    );
});

export const Password = forwardRef<HTMLInputElement, PasswordProps>(function Password(props, ref) {
    return (
        <InputBase component="password" {...props} ref={ref} />
    );
});