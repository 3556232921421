import React, {
    createContext,
    useContext,
} from "react";

import { useQuery } from "@apollo/client";

import { GetProducts } from "@api/query/products";

import { IExtCompanyMeta, useCompanyMeta } from "@api/companies";

import { useFeedbackContext } from "~/components/Feedback";

import { GetProductsQuery, GetProductsQueryVariables } from "@api/graphql/types";
import { IProduct } from "./types";
interface IProductsContextType {
    products?: IProduct[];
    companyMeta: IExtCompanyMeta | null;
    loading: boolean;
    setAutoSms: (v: boolean) => void;
    setDisableTradeValue: (v: boolean) => void;
}

const defaultContext: IProductsContextType = {
    products: undefined,
    companyMeta: null,
    loading: false,
    setAutoSms: (v: boolean) => { /* dummy Fn */ },
    setDisableTradeValue: (v: boolean) => { /* dummy Fn */ },
};

const ProductsContext = createContext<IProductsContextType>(defaultContext);

export const useProductsContext = () => {
    const context = useContext(ProductsContext);
    if (!context) {
        throw new Error("You cannot use the Products Context outside of its Provider!");
    }
    return context;
};

const order = [
    "AVA Credit",
    "AVA Trade",
    "AVA Buy",
    "AVA Trade Pro",
    "AVA Id",
    "AVA PreQual",
    "AVA Lender Connect",
    "AVA Reviews",
];

const useProductsContextValue = () => {

    const { handleOpenFeedback } = useFeedbackContext();

    const { actions, state } = useCompanyMeta();

    const setDisableTradeValue = async (v: boolean) => {
        try {
            await actions.updateCompanyMeta({
                tradeTool: {
                    disableTradeValue: v,
                },
            });
            handleOpenFeedback({ message: "Your changes has been saved!", severity: "success" });
        } catch (error) {
            console.error(error);
        }
    };

    const setAutoSms = async (v: boolean) => {
        actions.updateCompanyMeta({
            idTool: {
                autoSendSms: v,
            },
        });
        try {
            await actions.updateCompanyMeta({
                idTool: {
                    autoSendSms: v,
                },
            });
            handleOpenFeedback({ message: "Your changes has been saved!", severity: "success" });
        } catch (error) {
            console.error(error);
        }
    };

    const { data, loading } = useQuery<
        GetProductsQuery,
        GetProductsQueryVariables
    >(GetProducts, {
        onError(err) {
            console.error(err);
        }
    });

    const products = [...(data?.products?.nodes || [])];
    products?.sort((a, b) => {
        if (order.indexOf(a.displayName) < order.indexOf(b.displayName)) {
            return -1;
        }

        if (order.indexOf(a.displayName) > order.indexOf(b.displayName)) {
            return 1;
        }

        return 0;
    });

    return {
        products,
        companyMeta: state,
        setAutoSms,
        setDisableTradeValue,
        loading,
    };
};




export const ProductsProvider: React.FC<any> = ({ children }) => {
    const contextValue = useProductsContextValue();

    return (
        <ProductsContext.Provider value={contextValue}>
            {children}
        </ProductsContext.Provider>
    );
};